import React, { useState } from 'react';
import './App.css';

export default function App() {
  const [inputText, setInputText] = useState('');
  const [outputText, setOutputText] = useState('ENTER PASSWORD');
  const [isShaking, setIsShaking] = useState(false);
  const [password, setCodeBox] = useState(false);

  const handleChange = (e) => {
    setInputText(e.target.value);
    setIsShaking(false);
  };

  const handleKeyPress = () => {
    if (inputText === 'password') {
      setOutputText('your password must be made from numbers');
      setCodeBox(false);
    } else if (inputText === 'made from numbers') {
      setOutputText('password is incorrect');
      setCodeBox(false);
      setOutputText('please try again later');
    } else if (inputText === 'incorrect') {
      setCodeBox(false);
      setOutputText('try again');
    } else if (inputText === 'again') {
      setCodeBox(false);
      setOutputText('please try again later');
    } else if (inputText === 'again later') {
      setCodeBox(false);
      setOutputText(
        <div>
          <img src="https://i.imgflip.com/1hnuow.jpg" alt="Random" />
          <p>bruh.</p>
        </div>
      );
    } else {
      // Chaotic shaking animation for wrong code
      setOutputText('ENTER PASSWORD');
      const inputField = document.getElementById('password-input');
      inputField.classList.add('shake');
      setCodeBox(true);
      setTimeout(() => {
        inputField.classList.remove('shake');
        setInputText(''); // Clear input for wrong code
      }, 500);
    }
  };

  return (
    <div className="App" id="password-input">
      <h1>{outputText}</h1>
      <input
        id="password-input"
        type="text"
        value={inputText}
        onChange={handleChange}
        onKeyPress={(e) => {
          if (e.key === 'Enter') handleKeyPress();
        }}
        placeholder="Type here"
      />
      {password && <p>Not safe password!</p>}
    </div>
  );
}
